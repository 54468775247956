import React from 'react'
import { graphql } from "gatsby"
import BackgroundImg from 'gatsby-background-image'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ExitToIcon from '@material-ui/icons/ExitToApp'
import Fab from '@material-ui/core/Fab';
import ArrowFowardIosIcon from '@material-ui/icons/ArrowForwardIos';

import Layout from '../../../../../components/layout'
import SEO from '../../../../../components/seo'
import Contents from '../../../../../components/contents'
import Paragraphs from '../../../../../components/paragraph'
import Heading from '../../../../../components/heading'
import HeroBox from '../../../../../components/herobox'
import LinkButton from '../../../../../components/linkbutton'
import Gallery1 from '../../../../../components/gallery/isawaonsen/directionsFuji'
import Gallery2 from '../../../../../components/gallery/isawaonsen/directionsFujiIsawaonsen'
import Gallery3 from '../../../../../components/gallery/isawaonsen/directionsKawaguchiko'
import Gallery4 from '../../../../../components/gallery/isawaonsen/directionsKawaguchikoIsawaonsen'
import Gallery5 from '../../../../../components/gallery/isawaonsen/directionsKofu'
import Gallery6 from '../../../../../components/gallery/isawaonsen/directionsKofuNonstop'
import Gallery7 from '../../../../../components/gallery/isawaonsen/directionsKofuStop'
import Gallery8 from '../../../../../components/gallery/isawaonsen/directionsNagano'
import Gallery9 from '../../../../../components/gallery/isawaonsen/directionsNaganoNonstop'
import Gallery10 from '../../../../../components/gallery/isawaonsen/directionsNaganoStop'
import Gallery11 from '../../../../../components/gallery/isawaonsen/directionsOtsuki'
import Gallery12 from '../../../../../components/gallery/isawaonsen/directionsTokyo'

const useStyles = makeStyles(theme => ({
    root: {

    },
    root2: {
        padding: '0 30px',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    rootContainer: {
        padding: 0,
    },
    galleryContainer: {
        padding: '15px',
    },
    hero: {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '80vh',
        textAlign: 'center',
        color: '#ffffff',
    },
    heading1: {
        backgroundColor: 'rgba(72,189,152,.9)',
        padding: '20px;'
    },
    headingGallery: {
        Left: '-15px',
    },
    paragraphs: {
        paddingTop: '30px;'
    },
    paragraph: {
        backgroundColor: 'rgba(0,0,0,.5)',
    },
    button: {
        textAlign: 'right',
        marginBottom: '1.45rem',
        paddingRight: '30px',
    },
    fab: {
        backgroundColor: "#48bd96",
        color: "#ffe066",
        '&:hover': {
            backgroundColor: "#ffe066",
            color: "#48bd96",
        }
    },
}));

const IsawaonsenStationDirectionsPage = ({ data }) => {
    const classes = useStyles();
    const language = 'zh_hans';
    const slug = 'station/isawaonsen/directions';
    const station = data.railroadJson;
    const stationName = station.stationName[language];
    const stationAddress = station.address[language];
    let page = station.pages.filter(function(page, index) {
        if (page.slug === slug) {
            return true;
        }
    });
    page = page[0][language];
    const sections = page.sections;
    const heroImage = data.hero.childImageSharp.fluid;
    const gallery1 = 'railroad/'+station.prefecture_name+'/'+station.name+'/directions/fuji/gallery';
    const gallery2 = 'railroad/'+station.prefecture_name+'/'+station.name+'/directions/fuji/isawaonsen';
    const gallery3 = 'railroad/'+station.prefecture_name+'/'+station.name+'/directions/kawaguchiko/gallery';
    const gallery4 = 'railroad/'+station.prefecture_name+'/'+station.name+'/directions/kawaguchiko/isawaonsen';
    const gallery5 = 'railroad/'+station.prefecture_name+'/'+station.name+'/directions/kofu/gallery';
    const gallery6 = 'railroad/'+station.prefecture_name+'/'+station.name+'/directions/kofu/nonstop';
    const gallery7 = 'railroad/'+station.prefecture_name+'/'+station.name+'/directions/kofu/stop';
    const gallery8 = 'railroad/'+station.prefecture_name+'/'+station.name+'/directions/nagano/gallery';
    const gallery9 = 'railroad/'+station.prefecture_name+'/'+station.name+'/directions/nagano/nonstop';
    const gallery10 = 'railroad/'+station.prefecture_name+'/'+station.name+'/directions/nagano/stop';
    const gallery11 = 'railroad/'+station.prefecture_name+'/'+station.name+'/directions/otsuki/gallery';
    const gallery12 = 'railroad/'+station.prefecture_name+'/'+station.name+'/directions/tokyo/gallery';
    const sectionName = [
        'from-tokyo',
        'from-nagano',
        'from-kofu',
        'from-otsuki',
        'from-fujisan',
        'from-kawaguchiko'
    ];
    const internalLinkTitle = [
        '从东京地区（新宿）前往',
        '从长野地区（小渊泽）前往',
        '从甲府站前往',
        '从大月站前往',
        '从富士山站前往',
        '从河口湖站前往'
    ];
    let idx = -1;
    const notChild = false;
    return (
        <Layout language={language} slug={slug}>
            <SEO title={stationName} />
            <HeroBox
                image={heroImage}
                stationName={page.title}
                descriptions={page.descriptions}
                language={language}
            />
            <Container component="section" className={classes.rootContainer}>
                <Box component="article">
                    <section>
                        <Heading title="如何前往石和温泉站" isChild={notChild} />
                        <div className={classes.root2}>
                            {internalLinkTitle.map((title , i) => (
                                <React.Fragment>
                                    <LinkButton url={sectionName[i]} title={title} type="anchor"/>
                                    <br display={{xs: 'block', sm: 'none'}} />
                                </React.Fragment>
                            ))}
                        </div>
                    </section>
                {
                    sections.map((section, i) => {
                        if (!section.isChild) {
                            if ('article' === section.type) {
                                idx++;
                                return (
                                            <section id={sectionName[idx]} key={i}>
                                                <Heading title={section.title} isChild={section.isChild} />
                                                <Contents language={language} contents={section.contents} />
                                            </section>
                                        )
                            } else if ('link' === section.type) {

                            } else if ('gallery' === section.type) {
                                if (gallery1 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <Gallery1 idxSection={i} language={page.language} items={items} />
                                        </section>
                                    )
                                } else if (gallery2 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <Gallery2 idxSection={i} language={page.language} items={items} />
                                        </section>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery3 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <Gallery3 idxSection={i} language={page.language} items={items} />
                                        </section>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery4 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <Gallery4 idxSection={i} language={page.language} items={items} />
                                        </section>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery5 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <Gallery5 idxSection={i} language={page.language} items={items} />
                                        </section>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery6 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <Gallery6 idxSection={i} language={page.language} items={items} />
                                        </section>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery7 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <Gallery7 idxSection={i} language={page.language} items={items} />
                                        </section>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery8 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <Gallery8 idxSection={i} language={page.language} items={items} />
                                        </section>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery9 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <Gallery9 idxSection={i} language={page.language} items={items} />
                                        </section>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery10 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <Gallery10 idxSection={i} language={page.language} items={items} />
                                        </section>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery11 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <Gallery11 idxSection={i} language={page.language} items={items} />
                                        </section>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery12 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <Gallery12 idxSection={i} language={page.language} items={items} />
                                        </section>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else {

                                }
                            } else {

                            }
                        } else {
                            if ('article' === section.type) {
                                return (
                                            <section key={i}>
                                                <Heading title={section.title} isChild={section.isChild} />
                                                <Contents language={language} contents={section.contents} />
                                            </section>
                                        )
                            } else if ('link' === section.type) {

                            } else if ('gallery' === section.type) {
                                if (gallery1 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <div>
                                            {section.title ? <Heading title={section.title} isChild={section.isChild} className={classes.headingGallery} />: null}
                                            <section className={classes.galleryContainer} key={i}>
                                                <Gallery1 idxSection={i} language={page.language} items={items} />
                                            </section>
                                        </div>
                                    )
                                } else if (gallery2 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <div>
                                            {section.title ? <Heading title={section.title} isChild={section.isChild} className={classes.headingGallery} />: null}
                                            <section className={classes.galleryContainer} key={i}>
                                                <Gallery2 idxSection={i} language={page.language} items={items} />
                                            </section>
                                        </div>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery3 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <div>
                                            {section.title ? <Heading title={section.title} isChild={section.isChild} className={classes.headingGallery} />: null}
                                            <section className={classes.galleryContainer} key={i}>
                                                <Gallery3 idxSection={i} language={page.language} items={items} />
                                            </section>
                                        </div>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery4 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <div>
                                            {section.title ? <Heading title={section.title} isChild={section.isChild} className={classes.headingGallery} />: null}
                                            <section className={classes.galleryContainer} key={i}>
                                                <Gallery4 idxSection={i} language={page.language} items={items} />
                                            </section>
                                        </div>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery5 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <div>
                                            {section.title ? <Heading title={section.title} isChild={section.isChild} className={classes.headingGallery} />: null}
                                            <section className={classes.galleryContainer} key={i}>
                                                <Gallery5 idxSection={i} language={page.language} items={items} />
                                            </section>
                                        </div>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery6 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <div>
                                            {section.title ? <Heading title={section.title} isChild={section.isChild} className={classes.headingGallery} />: null}
                                            <section className={classes.galleryContainer} key={i}>
                                                <Gallery6 idxSection={i} language={page.language} items={items} />
                                            </section>
                                        </div>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery7 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <div>
                                            {section.title ? <Heading title={section.title} isChild={section.isChild} className={classes.headingGallery} />: null}
                                            <section className={classes.galleryContainer} key={i}>
                                                <Gallery7 idxSection={i} language={page.language} items={items} />
                                            </section>
                                        </div>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery8 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <div>
                                            {section.title ? <Heading title={section.title} isChild={section.isChild} className={classes.headingGallery} />: null}
                                            <section className={classes.galleryContainer} key={i}>
                                                <Gallery8 idxSection={i} language={page.language} items={items} />
                                            </section>
                                        </div>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery9 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <div>
                                            {section.title ? <Heading title={section.title} isChild={section.isChild} className={classes.headingGallery} />: null}
                                            <section className={classes.galleryContainer} key={i}>
                                                <Gallery9 idxSection={i} language={page.language} items={items} />
                                            </section>
                                        </div>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery10 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <div>
                                            {section.title ? <Heading title={section.title} isChild={section.isChild} className={classes.headingGallery} />: null}
                                            <section className={classes.galleryContainer} key={i}>
                                                <Gallery10 idxSection={i} language={page.language} items={items} />
                                            </section>
                                        </div>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery11 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <div>
                                            {section.title ? <Heading title={section.title} isChild={section.isChild} className={classes.headingGallery} />: null}
                                            <section className={classes.galleryContainer} key={i}>
                                                <Gallery11 idxSection={i} language={page.language} items={items} />
                                            </section>
                                        </div>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else if (gallery12 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <div>
                                            {section.title ? <Heading title={section.title} isChild={section.isChild} className={classes.headingGallery} />: null}
                                            <section className={classes.galleryContainer} key={i}>
                                                <Gallery12 idxSection={i} language={page.language} items={items} />
                                            </section>
                                        </div>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else {

                                }
                            } else {

                            }
                        }
                    })
                }</Box>
            </Container>
        </Layout>
        // <pre>{JSON.stringify(page, null, 4)}</pre>
    )
}

export const query = graphql`
  {
    railroadJson(name: {eq: "isawaonsen"}) {
      id
      heroImage
      address {
        en {
          address1
          address2
          city
          language
          prefecture
        }
        ja {
          address1
          address2
          city
          language
          prefecture
        }
        zh_hans {
          address1
          address2
          city
          prefecture
          language
        }
        zh_hant {
          address1
          address2
          city
          language
          prefecture
        }
      }
      name
      lon
      lat
      zip
      stationName {
        en
        ja
        zh_hans
        zh_hant
      }
      route_id
      prefecture_name
      prefecture_id
      pages {
        path
        slug
        fileName
        zh_hans {
          descriptions
          language
          subtitle
          title
          sections {
            isChild
            subtitle
            title
            type
            gallery {
              directory
              items {
                fileName
                title
              }
            }
            contents {
              paragraphs
              type
              link {
                title
                url
              }
              image {
                fileName
              }
            }
          }
        }
      }
    }
    hero:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/hero.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 1000, maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IsawaonsenStationDirectionsPage